import React from 'react';
import { Button, Modal } from "react-bootstrap";

class LinkTerminos extends React.Component {
    state = {};
    handleOpenTerminos(e){
        e.preventDefault();
        this.setState({ showModalTerminos: true });
    }

    handleCloseTerminos(e){
        this.setState({ showModalTerminos: false });
    }
    render(){
        return [
            <button type="button" className="link-button" onClick={ this.handleOpenTerminos.bind(this) }  key="modal-3">
                  Terminos del servicio
            </button>,
            <Modal bsSize="large" show={this.state.showModalTerminos} onHide={ this.handleCloseTerminos.bind(this) } key="modal-2">
                    <Modal.Header closeButton>
                    <Modal.Title  className="text-center">Aviso de privacidad y terminos de uso de datos.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-10 col-sm-offset-1 text-justify">
                                    <p> VALAVI DATA SYSTEMS S.A DE C.V. y SULTANA EMPRESARIAL S.A DE CV. (en adelante LAS EMPRESAS), se compromete a asegurar la privacidad de la información personal obtenida a través de sus servicios en línea. LAS EMPRESAS
                                        es una compañía de software que desarrolla y comercializa sistemas de administración empresarial en línea y estamos ubicados en Av. Adolfo Ruiz Cortines Poniente 3750 Col. Antonio I Villarreal C.P. 64390, Monterrey,
                                        Nuevo León, México y nuestro correo para contacto es contacto@valavi.mx. Nuestra prioridad es respetar la privacidad de sus datos de usuario y mantener segura toda la información que nuestros servicios recolectan.
                                        Este aviso de privacidad cubre la información proporcionada en los sitios web: https://www.valavi.com.mx, https://www.sulemp.com, https://clientes.sulemp.com, https://wiki.sulemp.com, https://www.haztufactura.com
                                        y https://factura.sulemp.com. Este aviso podría sufrir cambios en un futuro y de ser así se le avisará oportunamente en la sección de Aviso de privacidad de los sitios mencionados anteriormente. Si usted desea
                                        revocar el consentimiento respecto al tratamiento y uso de sus datos personales o solicitar el ejercicio de sus derechos ARCO (Acceso, Rectificación, Cancelación y Oposición), envíenos un correo electrónico
                                        a la siguiente dirección (soporte@valavi.mx).
                                    </p><br/>
                                    <p>DATOS PERSONALES QUE SOLICITAN Y REQUIEREN NUESTROS SERVICIOS. LAS EMPRESAS recoge información desde varias áreas y secciones de nuestros sitios web. La información que se solicita es requerida para la finalidad
                                        de funcionamiento del formulario en que es requerida, además esta información es almacenada en diferentes apartados de nuestro banco de datos, la información deberá ser veras y completa y el usuario responderá
                                        en todo momento por los datos proporcionados, en ningún caso LAS EMPRESAS es responsable de la veracidad de los mismo. La información recabada en los sitios es: • En los formularios de Contacto es. o Nombre,
                                        Correo Electrónico. • Al momento de solicitar un demo: o Nick de usuario, Empresa, Correo Electrónico. • Al momento de comprar. o Nick de usuario, Nombre completo, Correo Electrónico, Razón Social, Régimen Fiscal,
                                        Domicilio Fiscal, Número de tarjeta de crédito. • A registrar usuarios. o Nombre completo, nombre de usuario, correo electrónico, teléfono y celular • Al Registrar un Emisor o Razón social, Datos fiscales requeridos
                                        para la emisión de timbres, Teléfonos, Datos de contacto, Domicilio fiscal, Certificados de sello digital. • Al registrar un receptor (clientes) o Razón social, Datos fiscales requeridos para la emisión de timbres,
                                        Teléfonos, Datos de contacto, Domicilio fiscal. • Al registrar productos y servicios o Descripción precios y dataos requeridos para la emisión de timbres fiscales. • Al registrar empleados o Nombre, Información
                                        fiscal requerida para la emisión de nóminas fiscales. Teléfono y correo electrónico. En todo momento usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales, a fin
                                        de que dejemos de hacer uso de los mismos, para ello es necesario que presente su petición mediante un correo electrónico a soporte@valavi.mx, dicha petición deberá ir acompañada de fundamentos por los que solicita
                                        dicha revocación y asumiendo que el funcionamiento de nuestros servicios no se podría completar por la falta de esos datos personales. En un plazo no mayor a 5 días hábiles atenderemos su solicitud y le informaremos
                                        sobre la procedencia de la misma a través del correo electrónico del que provenga la solicitud.
                                    </p><br/>
                                    <p>USO DE COOKIES: Las cookies son pequeñas piezas de información que son enviadas por el sitio web a su navegador y se almacenan en el disco duro de su equipo y se utilizan para determinar preferencias cuando se conecta
                                        a los servicios de nuestros sitios, así como para rastrear comportamientos, generar estadísticas de uso o actividades llevadas a cabo por usted dentro de nuestros sitios. En algunas secciones y servicios de
                                        nuestro sitio requerimos que el cliente tenga habilitados las cookies ya que algunas de estas funcionalidades requieren de ellas para trabajar y funcionar correctamente. Las cookies nos permiten reconocerlo
                                        al momento de entrar a nuestros sitios y ofrecerle una experiencia personalizada para usted. Las cookies no se utilizan para identificar a los usuarios, a excepción de los casos que se investiguen actividades
                                        fraudulentas.
                                    </p><br/>
                                    <p>USO DE LA INFORMACION. La información solicitada por LAS EMPRESAS a través de nuestros sitios y servicios, permite contactar a los usuarios actuales y potenciales cuando sea necesario para ofrecerles algún producto
                                        o servicio, informarle de alguna mejora a los productos y servicios o mantenerlo al tanto de acontecimientos de LAS EMPRESAS y como esto puede apoyarlo en sus actividades dentro de nuestras plataformas. Asimismo,
                                        la información obtenida nos permite: • Proporcionarle un servicio eficiente • Informar sobré características, cambios y mejoras en nuestros productos y servicios. • Dar cumplimiento a las obligaciones contraídas
                                        con nuestros clientes • Proveer una eficiente atención a usuarios. </p>
                                    <p>LIMITE DE USO Y DIVULGACION DE INFORMACION. El acceso a la información es restringido por parte de LAS EMPRESAS solo el usuario y las personas que el mismo faculte pueden acceder a dicha información En nuestros
                                        servicios de notificación, promociones, ofertas a través de correo electrónico, solo LAS EMPRESAS tiene acceso a la información recabada. Este tipo de publicidad se realiza mediante avisos y mensajes promocionales
                                        de correo electrónico, los cuales solo serán enviados a usted y aquellos usuarios registrados para tales propósitos, esta indicación podrá usted modificarla en cualquier momento enviando un correo electrónico
                                        a soporte@valavi.mx. En el correo electrónicos podría incluirse ocasionalmente ofertas de terceras partes que sean de nuestros socios comerciales.
                                    </p><br/>
                                    <p>DERECHOS ARCO. Los datos proporcionados por el usuario forman parte de un archivo digital que contendrá su perfil. El usuario puede ejercer sus derechos ARCO a través del sistema o por medio del correo electrónico
                                        soporte@valavi.mx En el caso de optar por el correo electrónico el tiempo de respuesta de hasta 5 días hábiles. La solicitud deberá de ir acompañada de los argumentos por los que desea ejercer cualquiera de
                                        sus derechos, junto con una copia digitalizada de una identificación oficial del titular o de su apoderado. LAS EMPRESAS aconseja al usuario que actualice sus datos cada vez que estos sufran de alguna modificación
                                        ya que esto permitirá ofrecerle un servicio mas personalizado.
                                    </p><br/>
                                    <p>REMISIONES DE INFORMACION CON TERCEROS. LAS EMPRESAS únicamente realiza re-emisiones de información con los proveedores de Hosting, Timbrado, Almacenamiento y de Analítica con los que mantiene una relación jurídica
                                        vigente para poder mantener, actualizar y administrar sus servicios, sitios y plataformas, a través de los que informa a sus usuarios sobre actividades, promociones, eventos e información relevante de sus servicios.
                                    </p><br/>
                                    <p>DATOS PERSONALES SENCIBLES. LAS EMPRESAS no solicita datos sensibles en ninguno de sus servicios.
                                    </p><br/>
                                    <p>SEGURIDAD. LAS EMPRESAS ha incorporado medidas de seguridad, prevención y contención acordes a las mejores prácticas vigentes en la industria con el fin de salvaguardar en la medida de los posible la información
                                        contenida en nuestras bases de datos. Ninguna transmisión por medio de internet garantiza su seguridad al 100%. Una vez recibidos los datos haremos todo lo posible para salvaguardar su seguridad en nuestros
                                        sistemas, en este tema nuestro equipo de colaboradores ha enfocado sus esfuerzos para ofrecer tecnología más acorde a fin de ofrecerles la mayor seguridad posible y mitigar los riesgos de desviaciones, adulteración,
                                        perdida, consulta o tratamiento no autorizado de sus datos.
                                    </p><br/>
                                    <p>CONFIDENCIALIDAD DE INFORMACION. LAS EMPRESAS no vende ni alquila la información de sus usuarios, si los datos de usuarios deberían ser compartidos con socios, el usuario será notificado antes de que estos sean
                                        recogidos o transferidos, si el usuario no desea que sus datos sean compartidos, puede decidir no utilizar un servicio determinado o no participar en el fin por el cual la información está siendo utilizada.
                                        LAS EMPRESAS puede difundir la información del titular en casos especiales cuando a solicitud expresa de alguna autoridad, proporcionar esta información pueda servir para identificar , localizar o realizar acciones
                                        legales contra personas que pudiesen infringir las condiciones del servicio de nuestros sitios, o causar daños o interferencia sobre los derechos de LAS EMPRESAS o sus propiedades, de otros usuarios de nuestros
                                        servicios o de cualquier otra persona que pudiese resultar perjudicada por dichas actividades. LAS EMPRESAS no asume ninguna obligación de mantener confidencial cualquier otra información que el usuario proporcione
                                        por otros medios ajenos a los anteriormente mencionados.
                                    </p><br/>
                                    <p>PROTECCION DE LA INFORMACION PERSONAL Le recordamos que no revele su información personal, incluidos usuarios y contraseñas a nadie, LAS EMPRESAS no solicitaran su contraseña en ninguna llamada telefónica o correo
                                        electrónico. En todo momento el usuario es el responsable único y final de mantener en secreto sus datos de acceso a nuestros servicios, para disminuir los riesgos LAS EMPRESAS recomienda al usuario salir de
                                        su cuenta y cerrar la ventana del navegador cuando finalice su actividad, más aún si comparte el equipo de cómputo con alguien o utiliza un equipo público como una biblioteca o café internet.
                                    </p><br/>
                                    <p>CONFIDENCIALIDA A MENORES. LAS EMPRESAS no solicita información de identificación a los menores. Los menores siempre deben solicitar permiso a sus tutores antes de enviar información personal a otro usuario que
                                        se encuentre en línea.
                                    </p><br/>
                                    <p>ACEPTACION DE LOS TERMINOS Esta declaración de confidencialidad/Privacidad está sujeta a los términos y condiciones de los sitios web de LAS EMPRESAS antes descritos, lo cual constituye un acuerdo legal entre el
                                        usuario y LAS EMPRESAS Si el usuario utiliza los servicios y plataformas de LAS EMPRESAS significa que ha leído, entendido y acordado los términos antes expuestos. En caso de hacer una modificación a este aviso
                                        de Privacidad, LAS EMPRESAS notificara por correo electrónico a sus usuarios registrados con una anticipación no mayor a 10 días hábiles, y la publicara en el tablero de anuncios de nuestros servicios a fin
                                        de que todos los usuarios de LAS EMPRESAS queden enterados del cambio. Le recordamos que está en la facultad de acudir al IFAI en caso de considerar que ha sido vulnerado su derecho a la protección de datos
                                        personales.
                                    </p><br/>
                                    <p>
                                        Fecha de Actualización 7 de noviembre de 2018</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseTerminos.bind(this)}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
        ];
    }
}

export default LinkTerminos;