import React from 'react';
import { Button, FormGroup, Checkbox } from "react-bootstrap";
import { ToResult, localStorage } from '../../../Utils';
import { empresas } from '../Url';
import { RegexRFC } from '../../Cfdi/Models';
import { NotificationManager } from 'react-notifications';
import { Pasos } from '../../Cfdi/Models';
import TextField from '../../../Utils/Common/TextField';
import Required from '../../../Utils/Common/Required';
import LinkTerminos from './LinkTerminos';

const INIT_FORM = {
    _RFC:'',
    _Correo:'',
    _EmpresaID:'',
    _Registrarme: false,
    _AceptoTerminos: false
};

class Formulario extends React.Component {
    state = {
        empresas: [],
        empresa:{},
        form:{
            ...INIT_FORM
        }
    };
    componentDidMount(){
        this.ObtenerEmpresas();
    }

    ObtenerEmpresas(){
        ToResult.ToResultPost(
			empresas.url_listarempresas,
			{},
			(err, response) => {
				if (response.isValid) {
                    this.setState({ empresas: response.data });
                }
			}
		);
    }

    updateFormEmpresa( e ){
        const value = e.currentTarget.value;
        this.updateForm(e,() => {
            const empresa = this.state.empresas.filter(( empresa ) => empresa._EmpresaID === value )[0];
            localStorage.set('empresa', empresa );
            this.setState({ empresa });
        });
    }

    updateForm( { target }, callback= () => {} ){
        const { name, value, checked } = target;
        let { form } = this.state;
        if(target.type === "checkbox"){
            form = {
                ...form,
                [name]: checked 
            };
        } else {
            form = {
                ...form,
                [name]: name === '_RFC'? value.toUpperCase() : value
            };
        }
        this.setState({
            form
        }, callback );
    }

    onSubmitForm(e){
        e.preventDefault();
        const { form } = this.state;
        if(!form._AceptoTerminos){
            NotificationManager.warning("Es necesario aceptar terminos y condiciones.", "Advertencia");
            return;
        }
        if(!RegexRFC.test(form._RFC.trim())){
            NotificationManager.warning("El RFC ingresado no es un RFC correcto.", "Advertencia");
            this.setState({isInvalidRfc: true });
            return;
        }

        localStorage.set("informacion", {
            _RFC: form._RFC.trim(),
            _Correo: form._Correo.trim(),
            _Registrarme: form._Registrarme,
            _AceptoTerminos: form._AceptoTerminos
        } );
        localStorage.set('paso_anterior', Pasos.Inicio );
        window.location = `#/${this.state.empresa._Configuracion._DatosVenta._ShortName}`;
    }
    
    TieneEmpresa(){
        return this.props.nombreEmpresa;
    }
   

	render() {
        const { form, empresas } = this.state;
        return (
            <div className="col-xs-12 col-sm-6 col-md-6 col-md-offset-1">
                <div className="slide--action">
                    { this.TieneEmpresa() ?
                        <h3 className="text-center">
                            {this.props.nombreEmpresa}
                        </h3> 
                    : false }
                    <h6>¿Quieres tu factura?</h6>
                    <p>Coloca los datos a continuación y presiona "Quiero mi factura"</p>
                    <form className="mb-0" onSubmit={ this.onSubmitForm.bind(this) }>
                        <div className="row">
                            { !this.TieneEmpresa() ? 
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <label>Empresa:  <Required/></label>
                                    <select 
                                        name="_EmpresaID" 
                                        value={ form._EmpresaID } 
                                        onChange={ this.updateFormEmpresa.bind(this) } 
                                        className="form-control"
                                        required
                                    >
                                        <option value="" disabled>Seleccione una empresa</option>
                                        { empresas.map(( empresa, index ) => (
                                            <option value={empresa._EmpresaID} key={`empresa-${index}`}>{ empresa._NombreComercial }</option>
                                        )) }
                                    </select>
                                </div>
                            : false }
                            <TextField cols={!this.TieneEmpresa()?6:3} type="text" name="_RFC" value={ form._RFC } onChange={ this.updateForm.bind(this) } required >
                                Tu RFC
                            </TextField>
                        </div>
                        <div className="row">
                            <TextField cols={6} type="email" name="_Correo" value={ form._Correo } onChange={ this.updateForm.bind(this) } required>
                                Tu correo electrónico
                            </TextField>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <br/>
                                <FormGroup>
                                   {/* <Checkbox 
                                        name="_Registrarme" 
                                        checked={ form._Registrarme } 
                                        onChange={ this.updateForm.bind(this) }
                                    >
                                        Registrarme para notificaciones
                                    </Checkbox>
                                    */}
                                    <Checkbox 
                                        name="_AceptoTerminos"  
                                        checked={ form._AceptoTerminos } 
                                        onChange={ this.updateForm.bind(this) }
                                        required=""
                                    >
                                        Acepto los <LinkTerminos/>
                                    </Checkbox>
                                </FormGroup>
                            </div>
                        </div>
                        <div>
                            <Button className="btn btn--primary btn--block mt-10" type="submit">Quiero mi factura</Button>
                        </div>
                    </form>
                </div>
            </div>
        );
	}
}

export default Formulario;